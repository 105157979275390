import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import {
  GetUsersMembersEventsByEventIdQuery,
  useGetUsersMembersEventsByEventIdQuery,
} from '@shared/api';

interface Props {
  eventId: string;
}
const EventMembers = ({ eventId }: Props) => {
  const { t } = useTranslation();
  const { data: dataMembers, loading } = useGetUsersMembersEventsByEventIdQuery(
    {
      variables: {
        eventId,
        entityTypeId: '20616195-5dc8-4dc2-8c10-3da75a5ca73b',
      },
    },
  );

  let dataSource = dataMembers?.users_members_events || [];
  dataSource = dataSource.filter(
    item => item.user.name || item.user.first_name,
  );
  dataSource = dataSource.sort((a, b) => {
    const nameA = a.user.name?.toUpperCase() || '';
    const nameB = b.user.name?.toUpperCase() || '';
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  const columns: ColumnsType<
    GetUsersMembersEventsByEventIdQuery['users_members_events'][0]
  > = [
    {
      title: t('common.name'),
      dataIndex: 'user',
      render: user =>
        `${user.name?.toUpperCase() || ''} ${user.first_name || ''}`,
    },
    {
      title: t('common.gender'),
      dataIndex: 'user',
      render: user => {
        if (user.gender === 'MALE') return t('common.man');
        if (user.gender === 'FEMALE') return t('common.woman');
        if (user.gender === 'OTHER') return t('common.other');
        return '';
      },
    },
    {
      title: t('common.index'),
      dataIndex: 'data',
      render: data => data.index,
    },
  ];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={dataSource}
      loading={loading}
    />
  );
};

export { EventMembers };
